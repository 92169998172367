import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'
import Article from '../components/Article/Article'

const eventsTemplate = ( { data: { strapi: { event } }, location } ) => {
	
	const { eventTitle, eventArticle, eventSubTitle, eventLeftIllustration, eventRightIllustration, seoDescription } = event

	return (
		<>
			<SEO
				title={ `${ eventTitle } | Events` }
				description={ seoDescription }
				pathname={ location.pathname } />

			<Article 
				title={ eventTitle } 
				subTitle={ eventSubTitle } 
				content={ eventArticle } 
				leftIllustration={ eventLeftIllustration } 
				rightIllustration={ eventRightIllustration } />

		</>
	)
}

export default eventsTemplate

export const query = graphql`
	query eventQuery($id: ID!) {
		strapi {
			event( id: $id ){
				eventArticle
				eventTitle
				eventSubTitle
				eventLeftIllustration
				eventRightIllustration
				seoDescription
			}
		}
	}
`